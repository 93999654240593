var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.administration-add-report",
              modifiers: { "administration-add-report": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            "sidebar-class": "big_sidebar",
            id: "administration-add-report",
            right: "",
            backdrop: "",
            "no-slide": "",
            lazy: "",
            shadow: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: { shown: _vm.showSidebar },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c("div", { staticClass: "px-3 py-2 create-message" }, [
              _vm.step == 1
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "4",
                            label: _vm.$t("TYPE"),
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              name: "add_report_organisation_type",
                              options: _vm.options,
                            },
                            model: {
                              value: _vm.organisation_type,
                              callback: function ($$v) {
                                _vm.organisation_type = $$v
                              },
                              expression: "organisation_type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-md": "4",
                            label: _vm.$t("PERIOD"),
                          },
                        },
                        [
                          _c("MonthPicker", {
                            attrs: { previousMonth: "" },
                            model: {
                              value: _vm.from_date,
                              callback: function ($$v) {
                                _vm.from_date = $$v
                              },
                              expression: "from_date",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: {
                            disabled: _vm.disabledStep2,
                            variant: "primary",
                          },
                          on: { click: _vm.gotoStep2 },
                        },
                        [_vm._v(_vm._s(_vm.$t("NEXT")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step == 2
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form",
                        { staticClass: "mt-2", attrs: { inline: "" } },
                        [
                          _c("b-form-input", {
                            staticClass: "i-100",
                            attrs: { trim: "", placeholder: _vm.$t("SEARCH") },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.searchText,
                              callback: function ($$v) {
                                _vm.searchText = $$v
                              },
                              expression: "searchText",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("b-table", {
                        staticClass: "w-100 mt-2",
                        attrs: {
                          striped: "",
                          fixed: "",
                          hover: "",
                          small: "",
                          fields: _vm.fields,
                          items: _vm.filteredOrganisations,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "head(state)",
                              fn: function () {
                                return [
                                  _vm.items.length != 0
                                    ? _c("b-form-checkbox", {
                                        on: { change: _vm.addAll },
                                        model: {
                                          value: _vm.add_all,
                                          callback: function ($$v) {
                                            _vm.add_all = $$v
                                          },
                                          expression: "add_all",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "cell(sefos_node)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.yesOrNo(data.item.sefos_node)
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(id)",
                              fn: function (data) {
                                return [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      name: "check-button",
                                      value: data.item.id,
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data.item.name) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(state)",
                              fn: function (data) {
                                return [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      name: "check-button",
                                      value: data.item.id,
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2377336074
                        ),
                      }),
                      _c("b-pagination", {
                        staticClass: "mt-2",
                        attrs: {
                          "total-rows": _vm.totalItems,
                          "per-page": _vm.limit,
                          align: "center",
                          pills: "",
                        },
                        on: { input: _vm.getOrganisations },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                      _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                      _c("b-button", { on: { click: _vm.gotoStep1 } }, [
                        _vm._v(_vm._s(_vm.$t("BACK"))),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-fill",
                          attrs: {
                            disabled: _vm.disabledStep3,
                            variant: "primary",
                          },
                          on: { click: _vm.generateReports },
                        },
                        [_vm._v(_vm._s(_vm.$t("GENERATE")))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }