<template>
  <span>
    <span v-b-toggle.administration-add-report>
      <slot></slot>
    </span>

    <b-sidebar
      sidebar-class="big_sidebar"
      id="administration-add-report"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-close-on-backdrop
      no-close-on-esc
      @shown="showSidebar"
    >
      <b-overlay :show="loading" rounded="sm">
        <div class="px-3 py-2 create-message">
          <div v-if="step == 1">
            <b-form-group label-cols-md="4" :label="$t('TYPE')">
              <b-form-select  name="add_report_organisation_type" v-model="organisation_type" :options="options">
              </b-form-select>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('PERIOD')">
              <MonthPicker previousMonth v-model="from_date"></MonthPicker>     
            </b-form-group>

            <hr class="p-0 m-0 mt-4 mb-2" />

            <b-button
              :disabled="disabledStep2"
              @click="gotoStep2"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >
          </div>
          <div v-if="step == 2">
            <b-form inline class="mt-2">
              <b-form-input trim
                class="i-100"
                v-model="searchText"
                :placeholder="$t('SEARCH')"
                @keydown.enter.prevent
              ></b-form-input>
            </b-form>

            <b-table
              class="w-100 mt-2"
              striped
              fixed
              hover
              small
              :fields="fields"
              :items="filteredOrganisations"
            >
              <template #head(state)>
                <b-form-checkbox
                  v-if="items.length != 0"
                  v-model="add_all"
                  @change="addAll"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(sefos_node)="data">
                {{ yesOrNo(data.item.sefos_node) }}
              </template>

              <template v-slot:cell(id)="data">
                <b-form-checkbox
                  v-model="selected"
                  name="check-button"
                  :value="data.item.id"
                ></b-form-checkbox>
                {{ data.item.name }}
              </template>

              <template v-slot:cell(state)="data">
                <b-form-checkbox
                  v-model="selected"
                  name="check-button"
                  :value="data.item.id"
                ></b-form-checkbox>
              </template>
            </b-table>

            <b-pagination
              class="mt-2"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="limit"
              align="center"
              @input="getOrganisations"
              pills
            >
            </b-pagination>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="gotoStep1">{{ $t("BACK") }}</b-button>
            <b-button
              :disabled="disabledStep3"
              @click="generateReports"
              variant="primary"
              class="btn-fill"
              >{{ $t("GENERATE") }}</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
import MonthPicker from "@/components/Input/MonthPicker";
export default {
  components: { MonthPicker },
  data() {
    return {
      options: [
        { text: this.$t("AS_SERVICE"), value: "as-service" },
        { text: this.$t("STATUS_SEFOS"), value: "node" },
      ],
      organisation_type: "as-service",
      add_all: false,
      step: 1,
      process: 1,
      show: false,
      loading: false,
      from_date: null,
      items: [],
      selected: [],
      limit: 25,
      currentPage: 1,
      totalItems: 0,
      searchText: "",
      fields: [
        {
          key: "state",
          label: "",
          class: "text-break clickable hidden-mobile",
          thClass: "w-30",
        },
        {
          key: "id",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          key: "name",
          label: this.$t("NAME"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "unique_identifier",
          label: this.$t("ORGANISATION.UNIQUE_IDENTIFIER"),
          class: "text-break clickable hidden-mobile",
          sortable: false,
        },
        {
          key: "sefos_node",
          label: this.$t("AS_SERVICE"),
          class: "text-break hidden-mobile",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    addAll: function() {
      if (this.add_all) {
        this.selected = this.filteredOrganisations.map((a) => a.id);
      } else {
        this.selected = [];
      }
    },
    showSidebar() {
      var date = new Date();
      date.setDate(0);
      date.setDate(1);    
      this.process = 1;
      this.getOrganisations();
    },
    async generateReports() {
      this.loading = true;
      if(this.organisation_type == "as-service")
      {
        await this.generateReportsOrganisationsAsService();
      }else{
        await this.generateReportsAsNode();
      }
    },
    async generateReportsOrganisationsAsService() {
      let self = this;
      let org_ids = [];
      for (let ix = 0; ix < this.selectedAsService.length; ix++) {
        org_ids.push(this.selectedAsService[ix].id);
      }
      let payload = {
        month: this.from_date + "-01",
        org_ids: org_ids,
      };
      this.$http
        .post(this.user.hostname + "/billing/generate-report/services", payload)
        .then(() => {
          self.$noty.info(self.$t("BILLING.ADDED_REPORTS"));
          self.show = false;
          self.$emit("createdReport");
        })
        .catch(() => {
          self.loading = false;
        });
    },
    async generateReportsAsNode() {
      let org_ids = [];
      let self = this;
      for (let ix = 0; ix < this.selectedAsNode.length; ix++) {
        org_ids.push(this.selectedAsNode[ix].id);
      }
      let payload = {
        month: this.from_date + "-01",
        org_ids: org_ids,
      };
      this.$http
        .post(this.user.hostname + "/billing/generate-report/nodes", payload)
        .then(() => {
          self.generateReportsAsPartner();
        })
        .catch(() => {
          self.loading = false;
        });
    },
    async generateReportsAsPartner() {
      let org_ids = [];
      let self = this;
      for (let ix = 0; ix < this.selectedAsNode.length; ix++) {
        org_ids.push(this.selectedAsNode[ix].id);
      }
      let payload = {
        month: this.from_date + "-01",
        org_ids: org_ids,
      };
      this.$http
        .post(this.user.hostname + "/billing/generate-report/partners", payload)
        .then(() => {
          self.$noty.info(self.$t("BILLING.ADDED_REPORTS"));
          self.show = false;
          self.$emit("createdReport");
        })
        .catch(() => {
          self.loading = false;
        });
    },
    async gotoStep1() {
      this.step = 1;
    },
    async gotoStep2() {
      this.getOrganisations();
      this.step = 2;
    },
    yesOrNo(val) {
      return val == true ? this.$t("YES") : this.$t("NO");
    },
    getOrganisations: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/billing/organisation/list",
          {
            type: this.organisation_type,
            year_month: this.from_date,
            page: this.currentPage,
            limit: parseInt(this.limit),
            search_text: this.searchText,
          }
        )
        .then((response) => {
          self.items = response.data.items;
          self.totalItems = response.data.count;
        })
        .catch(() => {
        });
    },
    async gotoStep3() {}
  },
  computed: {
    disabledStep2: function() {
      if (this.from_time == "" || this.to_date == "") {
        return true;
      } else {
        return false;
      }
    },
    disabledStep3: function() {
      if (this.selected.length != 0) {
        return false;
      } else {
        return true;
      }
    },
    filteredOrganisations() {
      return this.items.filter(function(item) {
        return item.is_admin == 0;
      });
    },
    selectedOrganisations() {
      let self = this;
      return this.filteredOrganisations.filter(function(item) {
        return self.selected.includes(item.id);
      });
    },
    selectedAsNode() {
      return this.selectedOrganisations.filter(function(item) {
        return item.sefos_node == 1;
      });
    },
    selectedAsService() {
      return this.selectedOrganisations.filter(function(item) {
        return item.sefos_node == 0;
      });
    },
  },
  watch: {
    searchText: function() {
      if (this.searchText.length == 0) {
        this.getOrganisations();
      }
      if (this.searchText.length >= 2) {
        this.getOrganisations();
      }
    },
    limit: function() {
      this.getOrganisations();
    },
  },
  mounted() {
  },
};
</script>
<style></style>
