<template>
  <span v-if="date">
    <b-button class="no-border" @click="changePreviousMonth"
      ><i class="fal fa-chevron-left"></i
    ></b-button>
    <a class="btn no-border btn-secondary" defaul-month="1" href="#" tabindex="0" id="popover-date">{{
      value
    }}</a>
    <b-button class="no-border" @click="changeNextMonth"
      ><i class="fal fa-chevron-right"></i
    ></b-button>
    <b-popover
      custom-class="month-picker"
      target="popover-date"
      triggers="focus"
      placement="auto"
      ref="popover"
      :show.sync="popoverShow"
      @show="show"
    >
      <div class="text-center mt-3">
          <b-form inline class="mt-2 justify-content-center">
            <b-button class="no-border" @click="previousYear"
              ><i class="fal fa-chevron-left"></i
            ></b-button>
            <b-button class="no-border" :variant="getVariantYear">{{
              currentYear
            }}</b-button>
            <b-button class="no-border" @click="nextYear"
              ><i class="fal fa-chevron-right"></i
            ></b-button>
          </b-form>
          <div class="mt-2">
            <b-button class="no-border month-btn" @click="changeMonth(1)" :variant="getVariant(1)">{{ $t('MONTHS.01')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(2)" :variant="getVariant(2)">{{ $t('MONTHS.02')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(3)" :variant="getVariant(3)">{{ $t('MONTHS.03')}}</b-button>
            <br />
            <b-button class="no-border month-btn" @click="changeMonth(4)" :variant="getVariant(4)">{{ $t('MONTHS.04')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(5)" :variant="getVariant(5)">{{ $t('MONTHS.05')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(6)" :variant="getVariant(6)">{{ $t('MONTHS.06')}}</b-button>
            <br />
            <b-button class="no-border month-btn" @click="changeMonth(7)" :variant="getVariant(7)">{{ $t('MONTHS.07')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(8)" :variant="getVariant(8)">{{ $t('MONTHS.08')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(9)" :variant="getVariant(9)">{{ $t('MONTHS.09')}}</b-button>
            <br />
            <b-button class="no-border month-btn" @click="changeMonth(10)" :variant="getVariant(10)">{{ $t('MONTHS.10')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(11)" :variant="getVariant(11)">{{ $t('MONTHS.11')}}</b-button>
            <b-button class="no-border month-btn" @click="changeMonth(12)" :variant="getVariant(12)">{{ $t('MONTHS.12')}}</b-button>
          </div>
      </div>
    </b-popover>
  </span>
</template>
<script>
export default {
  props: ["value", "previousMonth"],
  components: {},
  data() {
    return {
      popoverShow: false,
      date: null,
      yearDate: null,
      year: null,
      currentDate: null
    };
  },
  methods: {
    show(){
      this.yearDate = this.date;
    },
    getVariant(month)
    {
      if(this.date.year == this.yearDate.year)
      {
        if(month == this.date.month)
        {
          return "selected";
        }else{
          if(this.currentDate.year == this.yearDate.year)
          {
            if(month == this.currentDate.month)
            {
              return "current";
            }else{
              return "";
            }
          }
        }      
      }else{
        if(this.currentDate.year == this.yearDate.year)
        {
          if(month == this.currentDate.month)
          {
            return "current";
          }else{
            return "";
          }
        }else{
          return "";
        }
      }
    },
    changeMonth(newMonth){
      this.date = this.$luxonDateTime.fromObject({ year: this.yearDate.year, month: newMonth });      
      this.$emit('input',this.date.toFormat('yyyy-MM'));
      this.$emit("change");
      this.popoverShow = false;
    },
    changePreviousMonth() {
      this.date = this.date.plus({ months: -1 });
      this.$emit('input',this.date.toFormat('yyyy-MM'));
      this.$emit("change");
      this.popoverShow = false;
    },
    changeNextMonth() {
      this.date = this.date.plus({ months: 1 });
      this.$emit('input',this.date.toFormat('yyyy-MM'));
      this.$emit("change");
      this.popoverShow = false;
    },
    previousYear() {
      this.yearDate = this.yearDate.plus({ years: -1 });
    },
    nextYear() {
      this.yearDate = this.yearDate.plus({ years: 1 });
    }
  },
  computed: {
    month: function(){
      if(this.date.month < 10)
      {
        return this.$t('MONTHS.0' + this.date.month);
      }else{
        return this.$t('MONTHS.' + this.date.month);
      }
    },
    currentMonth: function(){
      return this.date.toFormat('MM')
    },
    currentYear: function(){
      return this.yearDate.toFormat('yyyy')
    },
    getVariantYear()
    {
      if(this.date.year == this.yearDate.year)
      {
        return "selected";
      }else{
        if(this.currentDate.year == this.yearDate.year)
        {
          return "current";
        }else{
          return "";
        }
      }
    }
  },
  mounted() {        
    this.date = this.$luxonDateTime.utc();
    this.currentDate = this.date;
    if(this.previousMonth != undefined)
    {
      this.date = this.$luxonDateTime.utc().plus({ months: -1 });
    }
    this.yearDate = this.date;
    this.$emit('input',this.date.toFormat('yyyy-MM'));
  },
};
</script>
<style></style>
