<template>
  <section v-if="license != null">
    <b-nav tabs v-if="license != null && user.information.is_admin == 2">
      <b-nav-item :to="{ name: 'Administration.Billing.Reports' }">{{
        $t("BILLING.REPORTS")
      }}</b-nav-item>
      <b-nav-item v-if="1 == 0" :to="{ name: 'Administration.Billing.Service' }">{{
        $t("BILLING.SERVICE")
      }}</b-nav-item>
      <b-nav-item
        v-if="showNodes"
        :to="{ name: 'Administration.Billing.Node' }"
        >{{ $t("BILLING.NODE") }}</b-nav-item
      >

      <b-nav-item
        v-if="showPartnerBilling"
        :to="{ name: 'Administration.Billing.Partner' }"
      >
        {{ $t("BILLING.PARTNER") }}</b-nav-item
      >
    </b-nav>

    <b-form inline class="mt-2">
      <b-button @click="refreshView" class="no-border"
        ><i class="fal fa-sync"></i
      ></b-button>

      <AddReport
        @createdReport="createdReport"
        :license="license"
        :key="createdReportKey"
      >
        <b-btn class="inlineBtn" variant="dark">
          {{ $t("BILLING.ADD_REPORT") }}
        </b-btn>
      </AddReport>

      <MonthPicker
        class="ml-2"
        v-model="from_date"
        previousMonth
        @change="refreshView"
      ></MonthPicker>

      <b-form-input trim
        class="searchText newline-mobile"
        v-model="searchText"
        :placeholder="$t('SEARCH')"
        @keydown.enter.prevent
      ></b-form-input>

      <b-form-select  name="billing_limit" v-model="limit" class="limit">
        <b-form-select-option value="10">10</b-form-select-option>
        <b-form-select-option value="25">25</b-form-select-option>
        <b-form-select-option value="45">45</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
    </b-form>

    <router-view
      v-if="from_date"
      :key="refreshKey"
      :limit.sync="limit"
      :searchText.sync="searchText"
      :from_date="from_date"
      :license="license"
    ></router-view>
  </section>
</template>

<script>
import AddReport from "@/components/Administration/AddReport";
import MonthPicker from "@/components/Input/MonthPicker";
export default {
  components: { MonthPicker, AddReport },
  props: ["license"],
  data() {
    return {
      limit: 25,
      searchText: "",
      refreshIndex: 0,
      createdReportIndex: 0,
      menu: [],
      from_date: null,
    };
  },
  methods: {
    createdReport() {
      this.createdReportIndex = this.createdReportIndex + 1;
      this.refreshView();
    },
    refreshView() {
      this.refreshIndex = this.refreshIndex + 1;
    },
  },
  computed: {
    showOrganisations() {
      if (this.license.type == "RS" || this.license.type == "PA") {
        return true;
      } else {
        return this.license.multitenant == 1;
      }
    },
    showNodes() {
      return this.license.type == "RS" || this.license.type == "PA";
    },
    showPartnerBilling() {
      return this.license.type == "PA";
    },
    showBilling() {
      if (this.license.type == "RS" || this.license.type == "PA") {
        return true;
      } else {
        return this.license.multitenant == 1;
      }
    },
    refreshKey() {
      return "refreshEconomy_" + this.refreshIndex;
    },
    createdReportKey() {
      return "createdReport_" + this.createdReportIndex;
    },
  },
  mounted() {},
};
</script>
<style></style>
